import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { on } from 'delegated-events'
import {Navigation, Pagination, Autoplay} from 'swiper/modules'

gsap.registerPlugin(ScrollTrigger)

const handleDisplay = (swiper, controls, container) => {
  if (swiper.isBeginning && swiper.isEnd) {
    if (controls) {
      controls.classList.add('force-hidden')
    }

    container.classList.remove('fluid-right')
    container.querySelector('.swiper-wrapper').classList.add('justify-center')
    container.closest('.element-ourresults').classList.remove('has-controls')
  } else {
    if (controls) {
      controls.classList.remove('force-hidden')
    }

    container.classList.add('fluid-right')
    container.querySelector('.swiper-wrapper').classList.remove('justify-center')
    container.closest('.element-ourresults').classList.add('has-controls')
  }
}

const init = async () => {
  const sliders = document.querySelectorAll('.our-results-slider')

  if (sliders.length) {
    import('swiper/css')
    const { default: Swiper } = await import('swiper')

    Swiper.use([Navigation, Pagination, Autoplay])

    sliders.forEach((slider) => {
      const container = slider.querySelector('.swiper')
      const numSlides = slider.querySelectorAll('.swiper-slide').length
      const controls = slider.querySelector('.slider-controls')
      let pauseSwiper = slider.querySelector('.swiper-pause');
      let playSwiper = slider.querySelector('.swiper-play');
      const enableAutoPlay = container.dataset.swiperAutoplay === 'true';
      const skipSlides = container.dataset.skipSlides;

      if (numSlides > 1) {
        new Swiper(container, {
          slidesPerView: 'auto',
          slidesPerGroupAuto: skipSlides ? false : true,
          preloadImages: false,
          simulateTouch: false,
          pagination: {
            el: slider.querySelector('.slider-pagination'),
            type: 'custom',
            renderCustom: (_, current, total) => {
              return `${current} of ${total}`
            },
          },
          navigation: {
            nextEl: slider.querySelector('.slider-control-next'),
            prevEl: slider.querySelector('.slider-control-prev'),
          },
          on: {
            resize: (swiper) => handleDisplay(swiper, controls, container),
            afterInit: (swiper) => {
                handleDisplay(swiper, controls, container)
                if (enableAutoPlay) {
                    container.swiper.autoplay.start();
                }
            },
          },
          ...(enableAutoPlay && {
            autoplay: {
              delay: 0,
              disableOnInteraction: false,
            },
            freeMode: true,
            grabCursor: true,
            spaceBetween: 20,
            slidesPerView: 1,
            initialSlide: 0,
            loop:true,
            speed: 4500,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    speed: 4500,
                },
                640: {
                    slidesPerView: 2,
                    speed: 4500,
                },
                768: {
                    slidesPerView: 2,
                    speed: 5000,
                },
                1280: {
                    slidesPerView: 5,
                    speed: 5000,
                },
            },
          }),
        })

        if (enableAutoPlay) {
            on('click', '.swiper-pause', () => {
                container.swiper.translateTo(container.swiper.getTranslate(), 0);
                container.swiper.autoplay.stop();
                container.classList.add('paused');

                pauseSwiper.classList.add('invisible');
                playSwiper.classList.remove('invisible');
            });

            on('click', '.swiper-play', () => {
                const currentTranslate = container.swiper.getTranslate();
                const step = container.swiper.params.spaceBetween + container.swiper.slides[0].offsetWidth;

                container.swiper.setTranslate(currentTranslate - step);
                container.swiper.slideToClosest(4500, false);

                container.swiper.autoplay.start();
                container.classList.remove('paused');

                playSwiper.classList.add('invisible');
                pauseSwiper.classList.remove('invisible');
            });
        }
      }
    })
  }

  const elements = document.querySelectorAll('.element-ourresults')

  elements.forEach((el) => {
    if (el.dataset.disableAnimations !== undefined) {
      return
    }

    const statsWithBar = el.querySelectorAll('.stat-with-bar')

    if (statsWithBar.length) {
      statsWithBar.forEach((bar) => {
        gsap.set(bar, { height: 'auto' })
      })

      gsap.to(statsWithBar, {
        height: '100%',
        duration: 0.8,
        stagger: 0.2,
        paused: true,
        ease: 'power4',
        scrollTrigger: { trigger: el, start: 'top 80%' },
      })
    }
  })
}

init()
